import { default as aboutEx7UQPnComMeta } from "D:/projects/face-swap/pages/about.vue?macro=true";
import { default as indexl3srYEYL6yMeta } from "D:/projects/face-swap/pages/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("D:/projects/face-swap/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("D:/projects/face-swap/pages/index.vue").then(m => m.default || m)
  }
]