<template>
 <div class="container mx-auto max-w-screen-xl">
   <header class="pt-8 pb-8">
      <nav class="flex justify-between items-center">
         <a class="text-gray-500 text-2xl" href="">IMAGE AI</a>
         <!-- 让文本加上下划线-->
         <ul class="flex gap-6 border border-gray-400 border-solid rounded-full py-4 px-10">
            <li><a class="text-gray-500 hover:underline" href="">Sticker</a></li>
            <li><a class="text-gray-500 hover:underline" href="">FaceSwap</a></li>
            <li><a class="text-gray-500 hover:underline" href="">FaceToSticker</a></li>
            <li><a class="text-gray-500 hover:underline" href="">Blog</a></li>
             <li><a class="text-gray-500 hover:underline" href="">Feedback</a></li>
         </ul>
      </nav>
   </header>
  <!-- Your content here -->
   <div class="container">
   <NuxtPage />
   </div>
   <footer class="flex justify-center mt-40 py-8 text-gray-500">
      <p>&copy; 2023 Image AI. All rights reserved.</p>
   </footer>
</div>

</template>
